// import React from "react";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";  
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import * as Mui from "@mui/material";
// import Grid from "@mui/material/Grid2";
// import {DummyPersonalCard, PersonalCard} from "../Cards/cardv2";
// import {searchPersonalPorts, searchInvestPorts} from "../user.js";
// import SecureStorage from 'react-secure-storage';
// import News from './news.jsx'
// import Box from '@mui/material/Box';
// function MyPorts({setPortData}) {
//     const [portDatas, setPortDatas] = React.useState([]);
    
  
//     return (
//       <>
    
//     <Grid
//               container
//               sx={{
//                 backgroundColor: "#F6F5F5",
//                 height: "auto",
//                 borderLeft: "1px solid #08082380",
//                 // padding: "10px 20px 10px 20px",
//                 paddingTop: "10px",
//                 display: "flex",
//                 alignContent: "flex-start",
//                 justifyContent: "center",
//                 width: "420px",
//                 flexShrink: 0,
//               }}
//             >
//               <MyPortsSearchBar setPortDatas= {setPortDatas}/>
//               <Grid
//                 container
//                 spacing={4}
//                 sx={{
//                   paddingTop: "20px",
//                   paddingLeft: "20px",
//                   paddingRight: "20px",
//                   display: "flex",
//                   flexDirection: "column", // Items flow from top to bottom
//                   flexWrap: "nowrap", // Prevent wrapping to the next row
//                   justifyContent: "flex-start", // Align items to the top
//                   alignContent: "center", // Center items horizontally
//                   height: "90%", 
//                   overflowY: "auto", 
//                   overflowX: "hidden",
//                   scrollbarWidth: "none", 
//               "&::-webkit-scrollbar": { 
//                 display: "none",
//               },
//                 }}
//               >
//                 {portDatas.map((investment) => {
  
  
//                   // TODO set data here--------------------------------------------------------------------------------------
//                   return <PersonalCard pportData={investment}/>;
//                 })}

//                   {/* scrolling doesnt go all the way if these arent here */}
//                    <DummyPersonalCard/>
//                   {/*<DummyPersonalCard/>
//                   <DummyPersonalCard/>
//                   <DummyPersonalCard/> */}
//                   {/* <div style ={{height: "0px"}}></div> */}
          
//               </Grid>
            
//               {/* <News
//                 sx={{
//                   paddingTop: "20px",
//                   paddingLeft: "20px",
//                   paddingRight: "20px",
//                   paddingBottom: "50px",
//                   display: "flex",
//                   flexDirection: "column", // Items flow from top to bottom
//                   flexWrap: "nowrap", // Prevent wrapping to the next row
//                   justifyContent: "flex-start", // Align items to the top
//                   alignContent: "center", // Center items horizontally
//                   height: "100%", 
//                   // overflowY: "auto", 
//                   // overflowX: "hidden",
//               //     scrollbarWidth: "none", 
//               // "&::-webkit-scrollbar": { 
//               //   display: "none",
//               // },
//                 }} /> */}
//             </Grid>
            
        
         
//            </>

// //     <Box
// //   sx={{
// //     height: "100vh", // Full viewport height
// //     overflowY: "auto", // Enable vertical scrolling
// //     overflowX: "hidden", // Prevent horizontal scrolling
// //     scrollbarWidth: "thin", // For Firefox
// //     "&::-webkit-scrollbar": {
// //       width: "8px", // Scrollbar width for WebKit browsers
// //     },
// //     "&::-webkit-scrollbar-thumb": {
// //       backgroundColor: "#cccccc", // Scrollbar thumb color
// //       borderRadius: "4px", // Rounded corners for the scrollbar thumb
// //     },
// //     "&::-webkit-scrollbar-thumb:hover": {
// //       backgroundColor: "#aaaaaa", // Thumb color on hover
// //     },
// //     "&::-webkit-scrollbar-track": {
// //       backgroundColor: "#f0f0f0", // Scrollbar track color
// //     },
// //   }}
// // >
// //   <Grid
// //     container
// //     sx={{
// //       backgroundColor: "#F6F5F5",
// //       height: "auto",
// //       borderLeft: "1px solid #08082380",
// //       paddingTop: "10px",
// //       display: "flex",
// //       alignContent: "flex-start",
// //       justifyContent: "center",
// //       width: "420px",
// //       flexShrink: 0,
// //     }}
// //   >
// //     <MyPortsSearchBar setPortDatas={setPortDatas} />
// //     <Grid
// //       container
// //       spacing={4}
// //       sx={{
// //         paddingTop: "20px",
// //         paddingLeft: "20px",
// //         paddingRight: "20px",
// //         display: "flex",
// //         flexDirection: "column", // Items flow from top to bottom
// //         flexWrap: "nowrap", // Prevent wrapping to the next row
// //         justifyContent: "flex-start", // Align items to the top
// //         alignContent: "center", // Center items horizontally
// //         height: "50%",
// //         overflowY: "auto", // Enable vertical scrolling for this section
// //         overflowX: "hidden",
// //         scrollbarWidth: "none",
// //         "&::-webkit-scrollbar": {
// //           display: "none", // Hide scrollbars in WebKit browsers
// //         },
// //       }}
// //     >
// //       {portDatas.map((investment) => {
// //         // TODO set data here--------------------------------------------------------------------------------------
// //         return <PersonalCard pportData={investment} />;
// //       })}
// //     </Grid>

// //     <News
// //       sx={{
// //         paddingTop: "20px",
// //         paddingLeft: "20px",
// //         paddingRight: "20px",
// //         paddingBottom: "50px",
// //         display: "flex",
// //         flexDirection: "column", // Items flow from top to bottom
// //         flexWrap: "nowrap", // Prevent wrapping to the next row
// //         justifyContent: "flex-start", // Align items to the top
// //         alignContent: "center", // Center items horizontally
// //         height: "100%",
// //       }}
// //     />
// //   </Grid>
// // </Box>

//           );
//   }
  
//   function MyPortsSearchBar({setPortDatas}) {
//     const [selectedSortOption, setSelectedSortOption] = React.useState("title");
//     const [toggleCreateAndInvest, setToggleCreateAndInvest] = React.useState("create");
//     const [direction, setDirection] = React.useState("asc");
//     const [searchQuery, setSearchQuery] = React.useState("");
  
  
//     React.useEffect(() => {
//       const fetchData = async () => {
//         console.log("searching:",selectedSortOption);
//         let test;
//         if(toggleCreateAndInvest === "create"){
//          test = await searchPersonalPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
//         } else{
//           test = await searchInvestPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
//         }
//         console.log("searching:",test);
//         setPortDatas(test.content);
//       };
//       fetchData();
//       const intervalId = setInterval(fetchData, 60000);

//       return () => clearInterval(intervalId); 
//     }, [selectedSortOption, direction, searchQuery,toggleCreateAndInvest]);
  
//   const handleSortChange = (event) => {
//   setSelectedSortOption(event.target.value);
//   };
//   const handleSearchChange = (event) => {
//   setSearchQuery(event.target.value); // Update the searchQuery state
//   };
  
//   const  handlePortTypeToggle = () => {
//   if (toggleCreateAndInvest === "create") {
//     console.log("worked")
//     setToggleCreateAndInvest("invest");
//   }
//   else {
//     setToggleCreateAndInvest("create");
//   }
// };

//   const handleDirectionToggle = () => {
//     if (direction === "desc") {
//       setDirection("asc");
//     }
//     else {
//       setDirection("desc");
//     }
//   };
  
//     return (
//       <>
      
    
//       <Mui.Box
//         sx={{
//           width: "380px",
//           marginBottom: "40px",
//           display: "flex",
//           flexDirection: "column",
//           alignSelf: "center",
//           // height:" 10%"
//         }}
//       >
//         <Mui.Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             displayDirection: "row",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginBottom: "20px",
//           }}
//         >
//           <Mui.Typography
//             sx={{
//               color: "#080823",
//               fontWeight: "700",
//               fontSize: "28px",
//             }}
//           >
//             My Ports
//           </Mui.Typography>
//           <Mui.Box
//           sx = {{
//             display: "flex",
//             flexDirection: "row",
//             gap: "10px",
//           }}
//           >
//           <Mui.Select
//     id="dropdown-basic"
//     value={selectedSortOption}
//     onChange={handleSortChange}
//     sx={{
//       width: "155px",
//       height: "35px",
//       borderRadius: "5px",
//       fontSize: "20px",
//       border: "0.5px solid #08082380",
//       padding: "0 8px",
//       ".MuiOutlinedInput-notchedOutline": {
//         border: "none",
//       },
//     }}
//   > <Mui.MenuItem value="title">Title</Mui.MenuItem>
//     <Mui.MenuItem value="lastValue">Value</Mui.MenuItem>
//     <Mui.MenuItem value="risk">Risk</Mui.MenuItem>
//     <Mui.MenuItem value="dailyPnl">Daily Change</Mui.MenuItem>
    
//   </Mui.Select>
//   <Mui.IconButton
//     onClick={handlePortTypeToggle}
//     sx={{
//       width: "35px",
//       height: "35px",
//       borderRadius: "5px",
//       border: "0.5px solid #08082380",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "transparent",
//       "&:hover": {
//         backgroundColor: "#f0f0f0",
//       },
//     }}
//   >
//     {toggleCreateAndInvest === "create" ? (
//       <Mui.Typography>
//         C
//       </Mui.Typography>
//     ) : (
//       <Mui.Typography>
//         I
//       </Mui.Typography>
//     )}
//   </Mui.IconButton>
//   <Mui.IconButton
//     onClick={handleDirectionToggle}
//     sx={{
//       width: "35px",
//       height: "35px",
//       borderRadius: "5px",
//       border: "0.5px solid #08082380",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "transparent",
//       "&:hover": {
//         backgroundColor: "#f0f0f0",
//       },
//     }}
//   >
//     {direction === "asc" ? (
//       <ArrowUpwardIcon
//         sx={{
//           fontSize: "20px",
//           color: "#08082380",
//         }}
//       />
//     ) : (
//       <ArrowDownwardIcon
//         sx={{
//           fontSize: "20px",
//           color: "#08082380",
//         }}
//       />
//     )}
//   </Mui.IconButton>
//   </Mui.Box>
//         </Mui.Box>
//         <Mui.TextField
//           id="outlined-basic"
//           placeholder="Search My Ports"
//           value={searchQuery} 
//           onChange={handleSearchChange}
//           sx={{
//             width: "100%", // Set the width of the Mui.TextField
//             ".MuiOutlinedInput-root": {
//               height: "35px", // Set the height of the input field
//               borderRadius: "5px", // Rounded corners
//               "& fieldset": {
//                 border: "0.5px solid #08082380", // Border styling
//               },
//             },
//             ".MuiInputBase-input": {
//               padding: "8px", // Adjust padding inside the input
//               fontSize: "20px", // Adjust font size if necessary
//               paddingLeft: "30px",
//             },
//           }}
//         />
//       </Mui.Box>
       

//             </>
//     );
//   }

//   export default MyPorts;


// import React from "react";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";  
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import * as Mui from "@mui/material";
// import Grid from "@mui/material/Grid2";
// import {DummyPersonalCard, PersonalCard} from "../Cards/cardv2";
// import {searchPersonalPorts, searchInvestPorts} from "../user.js";
// import SecureStorage from 'react-secure-storage';
// import News from './news.jsx';

// function MyPorts({setPortData}) {
//     const [portDatas, setPortDatas] = React.useState([]);
    
//     return (
//       <Grid
//         container
//         sx={{
//           backgroundColor: "#F6F5F5",
//           height: "100vh",
//           color:"White",
//           borderLeft: "1px solid black",
//           width: "420px",
//           flexShrink: 0,
//           display: "flex",
//           flexDirection: "column",
//           overflow: "hidden"
//         }}
//       >
//         <MyPortsSearchBar setPortDatas={setPortDatas} 
//         sx={{
//           backgroundColor:"#111152",
//         }}/>
        
//         {/* Portfolio Cards Section */}
//         <Grid
//           container
//           spacing={4}
//           sx={{
//             flex: "1 1 50%", // Take up half the remaining space
//             paddingTop: "20px",
//             paddingLeft: "20px",
//             paddingRight: "20px",
//             display: "flex",
//             flexDirection: "column",
//             flexWrap: "nowrap",
//             alignContent: "center",
//             overflowY: "auto",
//             backgroundColor:"#111152",
//             overflowX: "hidden",
//             scrollbarWidth: "none",
//             "&::-webkit-scrollbar": {
//               display: "none",
//             },
//           }}
//         >
//           {portDatas.map((investment) => (
//             <PersonalCard key={investment.id} pportData={investment}/>
//           ))}
//           <DummyPersonalCard/>
//         </Grid>

      
       
//       </Grid>
//     );
// }

// function MyPortsSearchBar({setPortDatas}) {
//     const [selectedSortOption, setSelectedSortOption] = React.useState("title");
//     const [toggleCreateAndInvest, setToggleCreateAndInvest] = React.useState("create");
//     const [direction, setDirection] = React.useState("asc");
//     const [searchQuery, setSearchQuery] = React.useState("");
  
//     React.useEffect(() => {
//       const fetchData = async () => {
//         console.log("searching:", selectedSortOption);
//         let test;
//         if(toggleCreateAndInvest === "create"){
//          test = await searchPersonalPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
//         } else{
//           test = await searchInvestPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
//         }
//         console.log("searching:", test);
//         setPortDatas(test.content);
//       };
//       fetchData();
//       const intervalId = setInterval(fetchData, 60000);

//       return () => clearInterval(intervalId); 
//     }, [selectedSortOption, direction, searchQuery, toggleCreateAndInvest]);
  
//     const handleSortChange = (event) => {
//       setSelectedSortOption(event.target.value);
//     };
    
//     const handleSearchChange = (event) => {
//       setSearchQuery(event.target.value);
//     };
    
//     const handlePortTypeToggle = () => {
//       setToggleCreateAndInvest(prev => prev === "create" ? "invest" : "create");
//     };

//     const handleDirectionToggle = () => {
//       setDirection(prev => prev === "desc" ? "asc" : "desc");
//     };
  
//     return (
//       <Mui.Box
//         sx={{
//           width: "380px",
//           padding: "20px 0",
//           display: "flex",
//           flexDirection: "column",
//           alignSelf: "center",
//           color:"White",
//           flexShrink: 0,
//           backgroundColor:"#111152",
//         }}
//       >
//         <Mui.Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             color:"White",
//             displayDirection: "row",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginBottom: "20px",
//             backgroundColor:"#111152",
//           }}
//         >
//           <Mui.Typography
//             sx={{
//               color: "#080823",
//               fontWeight: "700",
//               fontSize: "28px",
//               backgroundColor:"#111152",
//               color:"White",
//             }}
//           >
//             My Ports
//           </Mui.Typography>
//           <Mui.Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               gap: "10px",
//               backgroundColor:"#111152",
//               color:"White",
//             }}
//           >
//             <Mui.Select
//               value={selectedSortOption}
//               onChange={handleSortChange}
//               sx={{
//                 width: "155px",
//                 height: "35px",
//                 borderRadius: "5px",
//                 fontSize: "20px",
//                 color:"White",
//                 border: "0.5px solid #08082380",
//                 padding: "0 8px",
//                 ".MuiOutlinedInput-notchedOutline": {
//                   border: "none",
//                 },
//                 backgroundColor:"#111152",
//               }}
//             >
//               <Mui.MenuItem value="title">Title</Mui.MenuItem>
//               <Mui.MenuItem value="lastValue">Value</Mui.MenuItem>
//               <Mui.MenuItem value="risk">Risk</Mui.MenuItem>
//               <Mui.MenuItem value="dailyPnl">Daily Change</Mui.MenuItem>
//             </Mui.Select>
//             <Mui.IconButton
//               onClick={handlePortTypeToggle}
//               sx={{
//                 width: "35px",
//                 height: "35px",
//                 borderRadius: "5px",
//                 border: "0.5px solid #08082380",
//                 color:"White",
//                 backgroundColor: "transparent",
//                 "&:hover": {
//                   backgroundColor: "#f0f0f0",
//                 },
//               }}
//             >
//               <Mui.Typography>
//                 {toggleCreateAndInvest === "create" ? "C" : "I"}
//               </Mui.Typography>
//             </Mui.IconButton>
//             <Mui.IconButton
//               onClick={handleDirectionToggle}
//               sx={{
//                 width: "35px",
//                 height: "35px",
//                 borderRadius: "5px",
//                 border: "0.5px solid #08082380",
//                 color:"White",
//                 backgroundColor: "transparent",
//                 "&:hover": {
//                   backgroundColor: "#f0f0f0",
//                 },
//               }}
//             >
//               {direction === "asc" ? (
//                 <ArrowUpwardIcon sx={{ fontSize: "20px", color: "#08082380" }} />
//               ) : (
//                 <ArrowDownwardIcon sx={{ fontSize: "20px", color: "#08082380" }} />
//               )}
//             </Mui.IconButton>
//           </Mui.Box>
//         </Mui.Box>
//         <Mui.TextField
//           placeholder="Search My Ports"
//           value={searchQuery} 
//           onChange={handleSearchChange}
//           sx={{
//             color:"White",
//             width: "100%",
//             ".MuiOutlinedInput-root": {
//               height: "35px",
//               borderRadius: "5px",
//               "& fieldset": {
//                 border: "0.5px solid #08082380",
//               },
//             },
//             ".MuiInputBase-input": {
//               padding: "8px",
//               fontSize: "20px",
//               paddingLeft: "30px",
//             },
//           }}
//         />
//       </Mui.Box>
//     );
// }

// export default MyPorts;
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";  
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import * as Mui from "@mui/material";
import Grid from "@mui/material/Grid2";
import {DummyPersonalCard, PersonalCard} from "../Cards/cardv2";
import {searchPersonalPorts, searchInvestPorts} from "../user.js";
import SecureStorage from 'react-secure-storage';
import News from './news.jsx';

function MyPorts({setPortData}) {
    const [portDatas, setPortDatas] = React.useState([]);
    
    return (
      <Grid
        container
        sx={{
          backgroundColor: "#111152",
          height: "100vh",
          color: "white",
          borderLeft: "1px solid black",
          width: "420px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden"
        }}
      >
        <MyPortsSearchBar setPortDatas={setPortDatas} 
        sx={{
          backgroundColor: "#111152",
        }}/>
        
        {/* Portfolio Cards Section */}
        <Grid
          container
          spacing={4}
          sx={{
            flex: "1 1 50%", // Take up half the remaining space
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignContent: "center",
            overflowY: "auto",
            backgroundColor: "#111152",
            overflowX: "hidden",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {portDatas.map((investment) => (
            <PersonalCard key={investment.id} pportData={investment}/>
          ))}
          <DummyPersonalCard/>
        </Grid>
      </Grid>
    );
}

function MyPortsSearchBar({setPortDatas}) {
    const [selectedSortOption, setSelectedSortOption] = React.useState("title");
    const [toggleCreateAndInvest, setToggleCreateAndInvest] = React.useState("create");
    const [direction, setDirection] = React.useState("asc");
    const [searchQuery, setSearchQuery] = React.useState("");
  
    React.useEffect(() => {
      const fetchData = async () => {
        console.log("searching:", selectedSortOption);
        let test;
        if(toggleCreateAndInvest === "create"){
         test = await searchPersonalPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
        } else{
          test = await searchInvestPorts(searchQuery, selectedSortOption, direction, 0, SecureStorage.getItem("userId"));
        }
        console.log("searching:", test);
        setPortDatas(test.content);
      };
      fetchData();
      const intervalId = setInterval(fetchData, 60000);

      return () => clearInterval(intervalId); 
    }, [selectedSortOption, direction, searchQuery, toggleCreateAndInvest]);
  
    const handleSortChange = (event) => {
      setSelectedSortOption(event.target.value);
    };
    
    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };
    
    const handlePortTypeToggle = () => {
      setToggleCreateAndInvest(prev => prev === "create" ? "invest" : "create");
    };

    const handleDirectionToggle = () => {
      setDirection(prev => prev === "desc" ? "asc" : "desc");
    };
  
    return (
      <Mui.Box
        sx={{
          width: "380px",
          padding: "20px 0",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          color: "white",
          flexShrink: 0,
          backgroundColor: "#111152",
        }}
      >
        <Mui.Box
          sx={{
            width: "100%",
            display: "flex",
            color: "white",
            displayDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            backgroundColor: "#111152",
          }}
        >
          <Mui.Typography
            sx={{
              fontWeight: "700",
              fontSize: "28px",
              backgroundColor: "#111152",
              color: "white",
            }}
          >
            My Ports
          </Mui.Typography>
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              backgroundColor: "#111152",
              color: "white",
            }}
          >
            <Mui.Select
              value={selectedSortOption}
              onChange={handleSortChange}
              sx={{
                width: "155px",
                height: "35px",
                borderRadius: "5px",
                fontSize: "20px",
                color: "white",
                border: "0.5px solid white",
                padding: "0 8px",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#111152",
                "& .MuiSelect-icon": {
                  color: "white"
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#111152",
                    color: "white"
                  }
                }
              }}
            >
              <Mui.MenuItem value="title" sx={{ color: "white", backgroundColor: "#111152" }}>Title</Mui.MenuItem>
              <Mui.MenuItem value="lastValue" sx={{ color: "white", backgroundColor: "#111152" }}>Value</Mui.MenuItem>
              <Mui.MenuItem value="risk" sx={{ color: "white", backgroundColor: "#111152" }}>Risk</Mui.MenuItem>
              <Mui.MenuItem value="dailyPnl" sx={{ color: "white", backgroundColor: "#111152" }}>Daily Change</Mui.MenuItem>
            </Mui.Select>
            <Mui.IconButton
              onClick={handlePortTypeToggle}
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "5px",
                border: "0.5px solid white",
                color: "white",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <Mui.Typography color="white">
                {toggleCreateAndInvest === "create" ? "C" : "I"}
              </Mui.Typography>
            </Mui.IconButton>
            <Mui.IconButton
              onClick={handleDirectionToggle}
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "5px",
                border: "0.5px solid white",
                color: "white",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              {direction === "asc" ? (
                <ArrowUpwardIcon sx={{ fontSize: "20px", color: "white" }} />
              ) : (
                <ArrowDownwardIcon sx={{ fontSize: "20px", color: "white" }} />
              )}
            </Mui.IconButton>
          </Mui.Box>
        </Mui.Box>
        <Mui.TextField
          placeholder="Search My Ports"
          value={searchQuery} 
          onChange={handleSearchChange}
          sx={{
            width: "100%",
            ".MuiOutlinedInput-root": {
              height: "35px",
              borderRadius: "5px",
              color: "white",
              "& fieldset": {
                border: "0.5px solid white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            ".MuiInputBase-input": {
              padding: "8px",
              fontSize: "20px",
              paddingLeft: "30px",
              color: "white",
              "&::placeholder": {
                color: "rgba(255, 255, 255, 0.7)",
                opacity: 1,
              },
            },
            "& .MuiInputLabel-root": {
              color: "white",
            },
          }}
          InputProps={{
            sx: {
              color: "white",
            }
          }}
        />
      </Mui.Box>
    );
}

export default MyPorts;